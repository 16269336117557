import React, { Component } from 'react';
import { Row, Spinner } from 'react-bootstrap';
import SiteContent from './SiteContent/SiteContent';
import Style from './Layout.module.scss'

class Layout extends Component {
    state = {
        accessToken: null
    };

    componentDidMount() {
        const locationHash = window.location.hash;
        if (locationHash) {
            let response = this.getKeyCloakResponseObjectFromLocationHash(locationHash);
            this.setState({accessToken: response.accessToken});
            window.location.hash = '';
        } else {
            this.redirectToLogin();
        }

    };

    redirectToLogin = () => {
        window.location = window.env.REACT_APP_SSO 
                                + '/auth/realms/DPD/protocol/openid-connect/auth?client_id=' 
                                + window.env.REACT_APP_CLIENT_ID 
                                + '&redirect_uri='
                                + window.env.REACT_APP_URL
                                + '&response_type=token';
    };

    getKeyCloakResponseObjectFromLocationHash = locationHash => {
        let responseWithoutHashMark = locationHash.slice(1);
        let fieldsArray = responseWithoutHashMark.split("&");
        if (this.isValidResponse(fieldsArray)) {
          return this.setKeyCloakFieldsFromFieldsArray(fieldsArray)
        }
    };
    
    isValidResponse = fieldsArray => {
        return fieldsArray && fieldsArray.length === 4;
    };
    
    setKeyCloakFieldsFromFieldsArray = fieldsArray => {
        let responseObject = this.createEmptyKeyCloakResponseObject();
        responseObject.sessionState = fieldsArray[0].split("=")[1];
        responseObject.accessToken = fieldsArray[1].split("=")[1];
        responseObject.tokenType = fieldsArray[2].split("=")[1];
        responseObject.expiresIn = parseInt(fieldsArray[3].split("=")[1]);
        return responseObject;
    };
    
    createEmptyKeyCloakResponseObject = () => {
        return {
          	sessionState: "",
          	accessToken: "",
          	tokenType: "",
          	expiresIn: 0,
        };
    };

	getContent = () => {
		if (this.state.accessToken) {
			return (
                <SiteContent 
                    refreshToken={ this.refreshAccessToken }
                    accessToken={ this.state.accessToken } 
                />
			);
		}
		return (
            <Row className={ Style.FallbackContent }>
                <Spinner className={ Style.SpinnerDiv } animation="border" variant="danger" size="lg" />
            </Row>
		);
    };
    
    refreshAccessToken = () => {
        this.setState({
            accessToken: null,
            isMenuOpen: false
        });
        window.location = window.location.origin;
    };

    render() {
        return this.getContent();   
    };
}

export default Layout;