import { Switch, TextareaAutosize, withStyles } from "@material-ui/core";
import Axios from "axios";
import React, { Component } from "react";
import DropDown from "../DropDown/DropDown";
import InactiveTextBox from "../InactiveTextBox/InactiveTextBox";
import MultiLink from "../MultiLink/MultiLink";
import TextBox from "../TextBox/TextBox";
import Style from "./ApplicationTool.module.scss";

class ApplicationTool extends Component {
    state = {
        id: null,
        name: null,
        url: null,
        description: null,
        isSsoReady: false,
        isEnabled: true,
        isMultiCard: false,
        category: null,
        links: null,
        categories: null,
        errorList: null,
        businessUnits: null,
        jiraValueList: null,
        jiraFieldValue: null,
        isAccessRequestEnabled: false,
		fileData: null,
		fileMimeType: null,
		supportUrl: null
    };

    componentDidMount() {
        this.getCategories();
    };

    getCategories = async () => {
        const categories = await this.getAxiosInstance().get(`/getCategories/` + this.props.accessToken);
        const jiraValueList = await this.getJiraFieldValueList();
        const app = this.props.edit;
        if (app) {
            this.setState({
                categories: categories.data,
                id: app.id,
                name: app.name,
                url: app.url,
                description: app.description,
                isSsoReady: app.isSsoReady,
                isEnabled: app.isEnabled,
                category: app.category,
                links: app.links,
                isMultiCard: app.url === '#blank',
                businessUnits: app.businessUnits,
                jiraValueList: jiraValueList,
                jiraFieldValue: { id: app.jiraFieldValue, name: app.jiraFieldValue },
                isAccessRequestEnabled: app.isAccessRequestEnabled,
				supportUrl: app.supportUrl
            });
        } else {
            this.setState({
                categories: categories.data,
                jiraValueList: jiraValueList
            });
        }
    };

	getJiraFieldValueList = async () => {
		const jiraMetaData = await this.getAxiosInstance().get('/getJiraMetaData');
		let jiraApplications;
		Object.entries(jiraMetaData.data.projects[0].issuetypes[0].fields).forEach(([key, jiraDataValue]) => {
			const jiraDataName = jiraDataValue.name;
			if (jiraDataName === 'SSOIN Application') {
				jiraApplications = jiraDataValue.allowedValues
					.map(app => {
						return { id: app.id, name: app.value };
					})
					.filter(app => !app.name.includes('TESTSD') && !(app.name.includes('Jira')));
			}
		});
		return jiraApplications;
	};

    getAxiosInstance = () => {
        return Axios.create({
            baseURL: window.env.REACT_APP_BACKEND + '/api/app'
        });
    };

    AntSwitch = withStyles((theme) => ({
        root: {
            width: 40,
            height: 18,
            padding: 0
        },
        switchBase: {
            padding: 3,
            color: theme.palette.common.white,
            '&$checked': {
                transform: 'translateX(22px)',
                color: theme.palette.common.white,
                '& + $track': {
                    opacity: 1,
                    backgroundColor: '#DC0032',
                    borderColor: '#DC0032',
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid #808285`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: '#808285',
        },
        checked: {},
    }))(Switch);

    toggleIsSSOReady = () => {
        this.setState({
            isSsoReady: !this.state.isSsoReady
        });
    };

    toggleIsEnabled = () => {
        this.setState({
            isEnabled: !this.state.isEnabled
        });
    };

    toggleIsAccessRequestEnabled = () => {
        this.setState({
            isAccessRequestEnabled: !this.state.isAccessRequestEnabled
        });
    };

    toggleIsMultiCard = () => {
        const toggledMultiCard = !this.state.isMultiCard;
        if (toggledMultiCard) {
            this.setState({
                isMultiCard: toggledMultiCard,
                url: '#blank'
            });
        } else {
            this.setState({
                isMultiCard: toggledMultiCard,
                url: null,
                links: null
            });
        }
    };

    getUrlInput = () => {
        if (this.state.isMultiCard) {
            return <InactiveTextBox placeHolder="Disabled for multiple links" />
        }
        return <TextBox
            placeHolder="Application URL"
            onChange={this.handleUrlChange}
            value={this.state.url || ''}
        />;
    };

    handleUrlChange = (event) => {
        this.setState({
            url: event.target.value
        });
    };

    handleDescriptionChange = (event) => {
        this.setState({
            description: event.target.value
        });
    };

    getMultiLink = () => {
        if (this.state.isMultiCard) {
            return (
                <MultiLink
                    onChange={this.handleMultiLinkChange}
                    links={this.state.links}
                />
            );
        }
    };

    handleMultiLinkChange = (newLinks) => {
        this.setState({
            links: newLinks
        });
    };

	handleSaveOnClick = async () => {
		const payload = this.createApplicationObject();
		const errorList = [...this.getErrorList(payload)];

		if (errorList.length === 0) {
			const formData = new FormData();
			formData.append('application', new Blob([JSON.stringify(payload)], { type: 'application/json' }));
			if (this.state.fileData) {
				formData.append('file', this.state.fileData);
				formData.append('fileMimeType', this.state.fileMimeType);
			}
			try {
				await this.getAxiosInstance().post('/addApplication/' + this.props.accessToken, formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				});

				this.setState({
					id: null,
					name: null,
					url: null,
					description: null,
					isSsoReady: false,
					isEnabled: true,
					isMultiCard: false,
					category: null,
					links: null,
					errorList: null,
					jiraFieldValue: null,
					isAccessRequestEnabled: false,
					file: null,
					fileMimeType: null,
					supportUrl: null
				});
				this.props.cancel();
			} catch (error) {
				console.log(error);
			}
		} else {
			this.setState({
				errorList: errorList
			});
		}
	};

    createApplicationObject = () => {
        return {
            id: this.state.id,
            name: this.state.name,
            url: this.state.url,
            description: this.state.description,
            isSsoReady: this.state.isSsoReady,
            isEnabled: this.state.isEnabled,
            isMultiCard: this.state.isMultiCard,
            category: this.state.category,
            links: this.state.links,
            businessUnits: this.state.businessUnits,
            jiraFieldValue: this.state.jiraFieldValue ? this.state.jiraFieldValue.name : null,
            isAccessRequestEnabled: this.state.isAccessRequestEnabled,
			supportUrl: this.state.supportUrl
        };
    };

    getErrorList = (application) => {
        let errorList = [];
		const description = this.state.description || '';

		if (this.isEmptyString(application.name)) {
            errorList.push('Application name can not be empty!');
        }
        if (this.isEmptyString(application.url)) {
            errorList.push('Application url can not be empty!');
        }

		if (this.isEmptyString(description) || description.length > 160) {
			errorList.push(`Application description can not be empty and must be shorter than 160 characters (currently ${description.length} characters)!`);
		}

        if (application.url === '#blank' && application.links.length < 2) {
            errorList.push('Multi-link application needs to have at least 2 links!');
        }
        if (application.url === '#blank' && !this.areValidLinks(application.links)) {
            errorList.push('Multi-link application link(s) are invalid. All links need a name and an url!');
        }
        if (!this.state.category) {
            errorList.push('Application category is required!');
        }
        if (!application.jiraFieldValue) {
            errorList.push('Jira Field value is required!');
        }
        return errorList;
    };

    isEmptyString = (string) => {
        return (!string || string.trim().length === 0);
    };

    isNotEmptyString = (string) => {
        return !this.isEmptyString(string);
    };

    areValidLinks = (linkList) => {
        let errorCount = 0;
        linkList.forEach(link => {
            if (this.isInvalidLink(link)) {
                errorCount++;
            }
        });
        return (errorCount === 0);
    };

    isValidLink = (link) => {
        return this.isNotEmptyString(link.text) && this.isNotEmptyString(link.url);
    };

    isInvalidLink = (link) => {
        return !this.isValidLink(link);
    };

    handleCategorySelection = (selectedCategory) => {
        this.setState({
            category: selectedCategory
        });
    };

    handleJiraFieldValueSelection = (selectedJiraValue) => {
        this.setState({
            jiraFieldValue: selectedJiraValue
        });
    };

    handleNameChange = (event) => {
        this.setState({
            name: event.target.value
        });
    };

	handleSupportUrlChange = (event) => {
		this.setState({
			supportUrl: event.target.value
		});
	}

	validateFile = (file) => {
		const maxSize = 5 * 1024 * 1024; // 5 MB
		const allowedFormats = [
			'application/pdf',
			'application/vnd.ms-powerpoint',
			'application/vnd.openxmlformats-officedocument.presentationml.presentation'];

		if (file.size > maxSize) {
			alert("File size exceeds the maximum limit of 5 MB.");
			return false;
		}
		if (!allowedFormats.includes(file.type)) {
			alert("Invalid file format. Only PDF and PPT files are allowed.");
			return false;
		}
		return true;
	};

	handleFileChange = (event) => {
		const file = event.target.files[0];

		if (file && this.validateFile(file)) {
			this.setState({ fileData: file });
		} else {
			this.setState({ fileData: null });
			event.target.value = '';
		}
	};

    getErrorMessages = () => {
        const errorList = this.state.errorList ? [...this.state.errorList] : [];
        let counter = 0;
        return errorList.map(error => {
            return (
                <div key={counter++}>{error}</div>
            );
        })
    };

    render() {
        return (
            <div className={Style.applicationToolsHolder} >
                <div className="title-holder">
                    <h4>
                        {this.props.edit ? 'Edit Application' : 'Add Application'}
                    </h4>
                </div>
                <div>
                    <div className={Style.applicationToolInputs}>
                        <div className={Style.applicationToolLabel}>
                            Does this application have multiple links?
                        </div>
                        <this.AntSwitch
                            color="primary"
                            checked={this.state.isMultiCard}
                            onChange={this.toggleIsMultiCard}
                        />
                    </div>
                    <div className={Style.applicationToolInputs}>
                        <div className={Style.applicationToolLabel}>
                            Does the application support SSO login?
                        </div>
                        <this.AntSwitch
                            color="primary"
                            checked={this.state.isSsoReady}
                            onChange={this.toggleIsSSOReady}
                        />
                    </div>
                    <div className={Style.applicationToolInputs}>
                        <div className={Style.applicationToolLabel}>
                            Should this application be shown for users?
                        </div>
                        <this.AntSwitch
                            color="primary"
                            checked={this.state.isEnabled}
                            onChange={this.toggleIsEnabled}
                        />
                    </div>
					<div className={Style.fileUploadContainer}>
						<input className={Style.fileUploadInput}
							   type="file"
							   onChange={this.handleFileChange}
							   accept=".pdf,.ppt,.pptx" />
					</div>
                    <div className={Style.applicationToolDropDown}>
                        <DropDown
                            placeHolder="Application Category"
                            items={this.state.categories ? this.state.categories : []}
                            onChange={this.handleCategorySelection}
                            value={this.state.category}
                        />
                    </div>
                    <div className={Style.applicationToolInputs}>
                        <TextBox
                            placeHolder="Application Name"
                            onChange={this.handleNameChange}
                            value={this.state.name || ''}
                        />
                    </div>
                    <div className={Style.applicationToolInputs}>
                        {this.getUrlInput()}
                    </div>
					<div className={Style.applicationToolInputs}>
						<TextBox
							placeHolder="Support Url"
							onChange={this.handleSupportUrlChange}
							value={this.state.supportUrl}
						/>
					</div>
                    <div className={Style.applicationToolInputs}>
                        <TextareaAutosize
                            className={Style.applicationDescription}
                            rowsMax={4}
                            placeholder="Application Description"
                            onChange={this.handleDescriptionChange}
							maxLength={160}
                            defaultValue={this.props.edit ? this.props.edit.description : ''}
                        />
                    </div>
                    {this.getMultiLink()}
                    <div className={Style.jiraFieldValueDropDown}>
                        <DropDown
                            placeHolder="Jira Field Value"
                            items={this.state.jiraValueList ? this.state.jiraValueList : []}
                            onChange={this.handleJiraFieldValueSelection}
                            value={this.state.jiraFieldValue}
                        />
                    </div>
                    <div className={Style.applicationToolInputs}>
                        <div className={Style.applicationToolLongLabel}>
                            Should access request for this application be enabled for users?
                        </div>
                        <this.AntSwitch
                            color="primary"
                            checked={this.state.isAccessRequestEnabled}
                            onChange={this.toggleIsAccessRequestEnabled}
                        />
                    </div>
                </div>
                <div className={Style.buttonHolder}>
                    <div className={Style.mainButton} onClick={this.handleSaveOnClick}>
                        {this.props.edit ? 'Edit Application' : 'Add Application'}
                    </div>
                    <div className={Style.greyButton} onClick={this.props.cancel} >
                        Cancel
                    </div>
                </div>
                <div className={Style.errorHolder}>
                    {this.getErrorMessages()}
                </div>
            </div>
        );
    };
}

export default ApplicationTool;