import React, { Component } from 'react';
import Style from './SingleLink.module.scss';
import LoginIcon from '../../../../../assets/pictoset/Login_icon.svg';

class SingleLink extends Component {

	getSingleLink = (
		<div className={Style.Link} onClick={this.props.onClickEvent}>
			<div>
				<div>
					<img className={Style.Icon} src={LoginIcon} alt={'InfoIcon'} />
				</div>
				<div>
            		<span className={Style.Text}>
               			{this.props.linkText}
            		</span>
				</div>
			</div>
		</div>
	);

	render() {
		return this.getSingleLink;
	}
}

export default SingleLink;