import React from 'react';
import { Component } from 'react';
import './InactiveTextBox.css';

class InactiveTextBox extends Component {
	render() {
		return (
			<div className='inactive-textbox'>
				<div className='inactive-textbox-content'>
					{this.props.placeHolder ? this.props.placeHolder : ''}
				</div>
			</div>
		);
	};
}

export default InactiveTextBox;