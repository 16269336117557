import React, { Component } from 'react';
import Style from './SearchBox.module.scss';

class SearchBox extends Component {
    state = {
        valueHolder: '',
        isFilteredListVisible: false,
        filteredApplications: []
    }

    handleChange = (event) => {
        const value = event.target.value;
        if (event && value.length >= 3) {
            const filteredList = this.props.apps.filter(application => application.name.toLowerCase().includes(value.toLowerCase()));
            this.setState({
                valueHolder: value,
                isFilteredListVisible: true,
                filteredApplications: filteredList
            });
        } else {
            this.setState({
                valueHolder: value,
                isFilteredListVisible: false
            });
            this.props.changeHandler(null);
        }
    }

    handleSelectApplication = (name) => {
        this.setState({
            valueHolder: name,
            isFilteredListVisible: false
        });
        this.props.changeHandler(name);
    }

	handleClick = () => {
		this.setState({
			isFilteredListVisible: false
		});
		if (this.state.valueHolder.length >= 3) {
			this.props.changeHandler(this.state.valueHolder);
		} else {
			this.props.changeHandler(null);
		}
	}

	handleKeyUp = (event) => {
		if (event.key === 'Enter') {
			this.handleClick();
		}
	};

    render() {
        return (
            <div className={ Style.InputGroup }>
                <div className={ Style.InputField }>
                    <input
                        type="text"
                        id="searchInput"
                        placeholder="Search"
                        onChange={ event => this.handleChange(event) }
						onKeyUp={ this.handleKeyUp }
                        value = { this.state.valueHolder }
                    />
                </div>
                <div className={ Style.InputButton } onClick={ this.handleClick }></div>
                {this.state.isFilteredListVisible && (
                    <div className={ Style.ListContainer }>
                        {this.state.filteredApplications.map(application =>
                            <li key={application.id} className={ Style.ListItem }>
                                <div onClick={() => this.handleSelectApplication(application.name)}>
                                    {application.name}
                                </div>
                            </li>)}
                    </div>
                )}
            </div>
        );
    }
}

export default SearchBox;