import React from 'react';
import { Component } from 'react';
import ApplicationTool from './ApplicationTool/ApplicationTool';
import BusinessUnitTool from './BusinessUnitTool/BusinessUnitTool';
import CategoryTool from './CategoryTool/CategoryTool';
import Axios from 'axios';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import './Administrative.css';
import AdminModal from './AdminModal/AdminModal';
import BannerTool from './BannerTool/BannerTool';

class Administrative extends Component {
    state = {
        applications: [],
        businessUnits: [],
        categories: [],
        isAppModalOpen: false,
        isBUModalOpen: false,
        isCategoryModalOpen: false,
        isAppScreenOpen: false,
        isAppTableOpen: false,
        isBuTableOpen: false,
        isCategoryTableOpen: false,
        isCategoryScreenOpen: false,
        isBuScreenOpen: false, 
        isMainScreen: true,
        app: null,
        bu: null,
        category: null,
        errorHappened: false,
        isBannerConfigScreenOpen: false
    };

    async componentDidMount() {
        const applications = await this.getInformation(`/getApplicationList/`);
        const categories = await this.getInformation(`/getCategories/`);
        const businessUnits = await this.getInformation(`/getBusinessUnits/`);
        this.setState({
            applications: applications,
            businessUnits: businessUnits,
            categories: categories
        });
    };

    getInformation = async (endpoint) => {
        try {
            const response = await this.getAxiosInstance().get('' + endpoint + this.props.accessToken);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    };

    getAxiosInstance = () => {
        return Axios.create({
            baseURL: window.env.REACT_APP_BACKEND + '/api/app'
        });
    };

    getApplicationsTable = () => {
        if (this.state.isAppTableOpen) {
            const applications = [...this.state.applications];
            return (
                <div>
                    <div className="title-holder">
                        <h4>Manage Applications</h4>
                    </div>
                    <div className="actions-holder">
                        <div className="main-button" onClick={ this.closeAppTable }>Go Back</div>
                        <div className="grey-button" onClick={ () => this.handleApplicationEdit(null) }>Add Application</div>
                    </div>
                    <TableContainer component={ Paper }>
                        <Table className="" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Application Name</TableCell>
                                    <TableCell align="center">Application Url</TableCell>
                                    <TableCell align="center">Application Description</TableCell>
									<TableCell align="center">Application Support Url</TableCell>
                                    <TableCell align="center">Application Category</TableCell>
                                    <TableCell align="center">SSO ready?</TableCell>
                                    <TableCell align="center">Enabled?</TableCell>
                                    <TableCell align="center">Access Request enabled?</TableCell>
                                    <TableCell align="center">Jira field value</TableCell>
                                    <TableCell align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { applications.map(app => (
                                    <TableRow key={ app.id }>
                                        <TableCell>{ app.name }</TableCell>
                                        <TableCell className="wrap-url">{ this.getApplicationUrl(app) }</TableCell>
                                        <TableCell className="wrap-description">{ app.description }</TableCell>
										<TableCell className="wrap-supportUrl">{ app.supportUrl }</TableCell>
                                        <TableCell>{ app.category.name }</TableCell>
                                        <TableCell align="center">{ app.isSsoReady ? 'Yes' : 'No' }</TableCell>
                                        <TableCell align="center">{ app.isEnabled ? 'Yes' : 'No' }</TableCell>
                                        <TableCell align="center">{ app.isAccessRequestEnabled ? 'Yes' : 'No' }</TableCell>
                                        <TableCell align="center">{ app.jiraFieldValue }</TableCell>
                                        <TableCell>{ this.getApplicationActions(app) }</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                         </Table>
                    </TableContainer>
                    <div className="actions-holder">
                        <div className="main-button" onClick={ this.closeAppTable }>Go Back</div>
                        <div className="grey-button" onClick={ () => this.handleApplicationEdit(null) }>Add Application</div>
                    </div>              
                </div>
            );
        }
    };

    closeAppTable = () => {
        this.setState({
            isAppTableOpen: false,
            isMainScreen: true
        });
    };

    getApplicationUrl = (app) => {
        if (app.url === '#blank') {
            return app.links.map(link => this.getApplicationLink(link));
        }
        return app.url;
    };

    getApplicationLink = (link) => {
        return (
            <div key={ link.id } className="table-app-link">
                <div>{ link.text }</div>
                <div>{ link.url }</div>
            </div>
        );
    };

    getApplicationActions = (app) => {
        return (
            <div className="table-action-holder">
                { this.getEditButton(() => this.handleApplicationEdit(app)) }
                { this.getDeleteButton(() => this.handleApplicationDelete(app)) }
            </div>
        );
    };

    getEditButton = (editFunction) => {
        return (
            <div onClick={ editFunction } className="table-edit-button">Edit</div>
        );
    };

    handleApplicationEdit = (app) => {
        this.setState({
            app: app,
            isAppScreenOpen: true,
            isAppTableOpen: false
        });
    };
    
    getDeleteButton = (deleteFunction) => {
        return (
            <div onClick={ deleteFunction } className="table-delete-button">X</div>
            );
        }; 
        
    handleApplicationDelete = (app) => {
        this.setState({
            app: app,
            isAppModalOpen: true
        });
    };

    getModals = () => {
        return (
            <div>
                <AdminModal
                    show={this.state.isAppModalOpen} 
                    modalTitle="Delete Application"
                    modalText={ this.getAppDeleteText() }
                    modalButtons={ this.getAppDeleteButtons() }
                    onHide={ this.handleAppDeleteModalClose }
                />
                <AdminModal
                    show={this.state.isBUModalOpen} 
                    modalTitle="Delete Application"
                    modalText={ this.getBUDeleteText() }
                    modalButtons={ this.getBUDeleteButtons() }
                    onHide={ this.handleBUDeleteModalClose }
                />
                <AdminModal
                    show={this.state.isCategoryModalOpen} 
                    modalTitle="Delete Category"
                    modalText={ this.getCategoryDeleteText() }
                    modalButtons={ this.getCategoryDeleteButtons() }
                    onHide={ this.handleCategoryDeleteModalClose }
                />
            </div>
        );
    };

    getAppDeleteText = () => {
        const app = this.state.app;
        return (
            <div>
                <div>You are gonna delete the following application:</div>
                <div className="delete-item">{ app ? app.name : '' }</div>
                <div>This action is not reversible!</div>
                <div>Are you sure you want to delete this application?</div>
            </div>
        );
    };

    getAppDeleteButtons = () => {
        return (
            <div>
                <div className="main-button" onClick={ this.handleDeleteApplication }>Delete</div>
                <div className="grey-button" onClick={ this.handleAppDeleteModalClose }>Cancel</div>
            </div>
        );
    };

    handleAppDeleteModalClose = () => {
        this.setState({
            isAppModalOpen: false
        });
    };

    getBUDeleteText = () => {
        const bu = this.state.bu;
        return (
            <div>
                <div>You are gonna delete the following business unit:</div>
                <div className="delete-item">{ bu ? bu.name : '' }</div>
                <div>This action is not reversible!</div>
                <div>Are you sure you want to delete this business unit?</div>
            </div>
        );
    };

    getBUDeleteButtons = () => {
        return (
            <div>
                <div className="main-button" onClick={ this.handleDeleteBU }>Delete</div>
                <div className="grey-button" onClick={ this.handleBUDeleteModalClose }>Cancel</div>
            </div>
        );
    };

    handleBUDeleteModalClose = () => {
        this.setState({
            isBUModalOpen: false
        });
    };

    handleCategoryDeleteModalClose = () => {
        this.setState({
            isCategoryModalOpen: false
        });
    };

    getCategoryDeleteButtons = () => {
        return (
            <div>
                <div className="main-button" onClick={ this.handleDeleteCategory }>Delete</div>
                <div className="grey-button" onClick={ this.handleCategoryDeleteModalClose }>Cancel</div>
            </div>
        );
    };

    handleDeleteCategory = async () => {
        const category = this.state.category;
        try {
            await this.getAxiosInstance().post(`/deleteCategory/` + category.id + `/` + this.props.accessToken);
            const categories = await this.getInformation(`/getCategories/`);
            this.setState({
                category: null,
                categories: categories
            });
            this.handleCategoryDeleteModalClose();
        } catch(error) {
            console.log(error);
        }
    };

    getCategoryDeleteText = () => {
        const category = this.state.category;
        return (
            <div>
                <div>You are gonna delete the following application category:</div>
                <div className="delete-item">{ category ? category.name : '' }</div>
                <div>This action is not reversible!</div>
                <div>Are you sure you want to delete this application category?</div>
            </div>
        );
    };

    handleDeleteBU = async () => {
        const bu = this.state.bu;
        try {
            await this.getAxiosInstance().post(`/deleteBusinessUnit/` + bu.name + `/` + this.props.accessToken);
            const applications = await this.getInformation(`/getApplicationList/`);
            const businessUnits = await this.getInformation(`/getBusinessUnits/`);
            this.setState({
                bu: null,
                applications: applications,
                businessUnits: businessUnits
            });
            this.handleBUDeleteModalClose();
        } catch (error) {
            console.log(error);
        }
    };

    handleDeleteApplication = async () => {
        const app = this.state.app;
        try {
            await this.getAxiosInstance().post(`/deleteApplication/` + app.id + `/` + this.props.accessToken);
            const applications = await this.getInformation(`/getApplicationList/`);
            this.setState({
                app: null,
                applications: applications
            });
            this.handleAppDeleteModalClose();
        } catch(error) {
            console.log(error);
        }
    };

    getAppEditScreen = () => {
        if (this.state.isAppScreenOpen && this.state.app) {
            return (
                <ApplicationTool 
                    edit={ this.state.app } 
                    accessToken={ this.props.accessToken } 
                    cancel={ this.handleAppEditCancel }
                />
            );
        }
        if (this.state.isAppScreenOpen) {
            return (
                <ApplicationTool 
                    accessToken={ this.props.accessToken } 
                    cancel={ this.handleAppEditCancel }
                />
            );
        }
    };

    handleAppEditCancel = async () => {
        const applications = await this.getInformation(`/getApplicationList/`);
        this.setState({
            app: null,
            isAppScreenOpen: false,
            isAppTableOpen: true,
            applications: applications
        });
    };

    manageApplications = () => {
        this.setState({
            isMainScreen: false,
            isAppTableOpen: true
        });
    };

    manageBusinessUnits = () => {
        this.setState({
            isMainScreen: false,
            isBuTableOpen: true
        });
    };

    manageCategories = () => {
        this.setState({
            isMainScreen: false,
            isCategoryTableOpen: true
        });
    };

    manageBanner = () => {
        this.setState({
            isMainScreen: false,
            isBannerConfigScreenOpen: true
        })
    }

    getOptions = () => {
        if (this.state.isMainScreen) {
            return (
                <div>
                    <div className="title-holder">
                        <h4>Admin options</h4>
                    </div>
                    <div onClick={ this.manageApplications } className="secondary-button">Manage Applications</div>
                    <div onClick={ this.manageBusinessUnits } className="secondary-button">Manage Business Units</div>
                    <div onClick={ this.manageCategories } className="secondary-button">Manage Application Categories</div>
                    <div onClick={ this.manageBanner } className="secondary-button">Manage Banner</div>
                </div>
            );
        }
    };

    closeBuTable = () => {
        this.setState({
            isMainScreen: true,
            isBuTableOpen: false
        });
    };

    getApplicationsName = (applicationIdList) => {
        return applicationIdList.map(id => {
            return (
                <div key={ id }>{ this.getApplicationName(id) }</div>
            );
        });
    };

    getApplicationName = (id) => {
        const applications = this.state.applications ? [...this.state.applications] : [];
        const application = applications.filter(app => app.id === id)
        if (application) {
            return application[0].name;
        }
        return '';
    };

    handleBUDelete = (bu) => {
        this.setState({
            bu: bu,
            isBUModalOpen: true
        });
    };
    
    getBUActions = (bu) => {
        return (
            <div className="table-action-holder">
                { this.getEditButton(() => this.handleBUEdit(bu)) }
                { this.getDeleteButton(() => this.handleBUDelete(bu)) }
            </div>
        );
    };

    handleBUEdit = (bu) => {
        this.setState({
            bu: bu,
            isBuScreenOpen: true,
            isBuTableOpen: false
        });
    };

    getBusinessUnitsTable = () => {
        if (this.state.isBuTableOpen) {
            const businessUnits = [...this.state.businessUnits];
            let index = 0;
            return (
                <div>
                    <div className="title-holder">
                        <h4>Manage Business Units</h4>
                    </div>
                    <div className="actions-holder">
                        <div className="main-button" onClick={ this.closeBuTable }>Go Back</div>
                        <div className="grey-button" onClick={ () => this.handleBUEdit(null) }>Add Business Unit</div>
                    </div>
                    <div className="bu-table">
                        <TableContainer component={ Paper }>
                            <Table className="" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Business Unit Name</TableCell>
                                        <TableCell align="center">Application List</TableCell>
                                        <TableCell align="center">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { businessUnits.map(bu => (
                                        <TableRow key={ index++ }>
                                            <TableCell>{ bu.name }</TableCell>
                                            <TableCell>{ this.getApplicationsName(bu.applicationIdList) }</TableCell>
                                            <TableCell>{ this.getBUActions(bu) }</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                             </Table>
                        </TableContainer>
                    </div>
                    <div className="actions-holder">
                        <div className="main-button" onClick={ this.closeBuTable }>Go Back</div>
                        <div className="grey-button" onClick={ () => this.handleBUEdit(null) }>Add Business Unit</div>
                    </div>            
                </div>
            );
        }
    };

    getBUEditScreen = () => {
        if (this.state.isBuScreenOpen && this.state.bu) {
            return (
                <BusinessUnitTool 
                    edit={ this.state.bu }
                    applicationNames={ [...this.state.applications].filter(app => this.state.bu.applicationIdList.includes(app.id)).map(app => app.name) }
                    acessToken={ this.props.accessToken } 
                    cancel={ this.closeBUEditScreen }
                />
            );
        }
        if (this.state.isBuScreenOpen) {
            return (
                <BusinessUnitTool 
                    acessToken={ this.props.accessToken } 
                    cancel={ this.closeBUEditScreen }
                />
            );
        }
    };

    closeBUEditScreen = async () => {
        const businessUnits = await this.getInformation(`/getBusinessUnits/`);
        this.setState({
            bu: null,
            businessUnits: businessUnits,
            isBuScreenOpen: false,
            isBuTableOpen: true
        });
    };

    getCategoriesTable = () => {
        if (this.state.isCategoryTableOpen) {
            const categories = this.state.categories ? [...this.state.categories] : [];
            return (
                <div>
                    <div className="title-holder">
                        <h4>Manage Application Categories</h4>
                    </div>
                    <div className="actions-holder">
                        <div className="main-button" onClick={ this.closeCategoryTable }>Go Back</div>
                        <div className="grey-button" onClick={ () => this.handleCategoryEdit(null) }>Add Category</div>
                    </div>
                    <div className="bu-table">
                        <TableContainer component={ Paper }>
                            <Table className="" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Category Name</TableCell>
                                        <TableCell align="center">Application List</TableCell>
                                        <TableCell align="center">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { categories.map(category => (
                                        <TableRow key={ category.id }>
                                            <TableCell>{ category.name }</TableCell>
                                            <TableCell>{ this.getCategoryApplications(category) }</TableCell>
                                            <TableCell>{ this.getCategoryActions(category) }</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                             </Table>
                        </TableContainer>
                    </div>
                    <div className="actions-holder">
                        <div className="main-button" onClick={ this.closeCategoryTable }>Go Back</div>
                        <div className="grey-button" onClick={ () => this.handleCategoryEdit(null) }>Add Category</div>
                    </div>            
                </div>
            );
        }
    };

    getCategoryApplications = (category) => {
        const applications = [...this.state.applications];
        return applications.filter(app => app.category.id === category.id).map(app => {
            return (
                <div key={ app.id }>{ app.name }</div>
            );
        });
    };

    closeCategoryTable = () => {
        this.setState({
            isCategoryTableOpen: false,
            isMainScreen: true
        });
    };

    getCategoryActions = (category) => {
        const applications = [...this.state.applications];
        const isEmptyCategory = applications.filter(app => app.category.id === category.id).length === 0;
        return (
            <div className="table-action-holder">
                { this.getEditButton(() => this.handleCategoryEdit(category)) }
                { isEmptyCategory ? this.getDeleteButton(() => this.handleCategoryDelete(category)) : <div></div> }
            </div>
        );
    };

    handleCategoryEdit = (category) => {
        this.setState({
            category: category,
            isCategoryScreenOpen: true,
            isCategoryTableOpen: false
        });
    };

    handleCategoryDelete = (category) => {
        this.setState({
            category: category,
            isCategoryModalOpen: true
        });
    };
    
    getCategoryEditScreen = () => {
        if (this.state.isCategoryScreenOpen && this.state.category) {
            return (
                <CategoryTool 
                    accessToken={ this.props.accessToken }
                    edit={ this.state.category } 
                    cancel={ this.closeCategoryEditScreen }
                />
            );
        }
        if (this.state.isCategoryScreenOpen) {
            return (
                <CategoryTool 
                    accessToken={ this.props.accessToken } 
                    cancel={ this.closeCategoryEditScreen }
                />
            );
        }
    };

    closeCategoryEditScreen = async () => {
        const categories = await this.getInformation(`/getCategories/`);
        this.setState({
            category: null,
            categories: categories,
            isCategoryScreenOpen: false,
            isCategoryTableOpen: true
        });
    };

    getBannerConfigScreen = () => {
        if(this.state.isBannerConfigScreenOpen){
            return (
                <BannerTool 
                    accessToken={ this.props.accessToken}
                    cancel={ this.closeBannerConfigScreen }
                />
            );
        }
    };

    closeBannerConfigScreen =  () => {
        this.setState({
            isMainScreen: true,
            isBannerConfigScreenOpen: false 
        });
    }

    render() {
        return (
            <div className="content-holder">
                <div className="welcome-text">
                    <h3>
                        Administrative Tools
                    </h3>
                </div>
                <div>
                    { this.getModals() }
                    { this.getOptions() }
                    { this.getApplicationsTable() }
                    { this.getBusinessUnitsTable() }
                    { this.getCategoriesTable() }
                    { this.getAppEditScreen() }
                    { this.getBUEditScreen() }
                    { this.getCategoryEditScreen() }
                    { this.getBannerConfigScreen()}
                </div>
            </div>
        );
    };
}

export default Administrative;