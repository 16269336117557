import React, { Component } from 'react';
import SSOPicture from '../../../../../assets/pictoset/Security_blackred_pos_rgb.svg';
import Style from './SSOLogo.module.scss';

class SSOLogo extends Component {
	render() {
		if (this.props.ssoReady) {
			return (
				<div className='SSO_logo'>
					<img src={SSOPicture} alt='SSO logo' className={Style.SSO_picture} />
					<span className={Style.SSO_text}>SSO</span>
				</div>
			);
		} else {
			return null;
		}
	}
}

export default SSOLogo;