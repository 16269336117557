import Axios from 'axios';
import React, { Component } from 'react';
import { Collapse, Spinner } from 'react-bootstrap';
import Administrative from '../Administrative/Administrative';
import FooterCopyright from '../Footer/FooterCopyRight';
import Header from '../Header/Header';
import ApplicationCard from './ApplicationCard/ApplicationCard';
import DpdModal from './DpdModal/DpdModal'
import FilterButton from './FilterButton/FilterButton';
import SearchBox from './SearchBox/SearchBox';
import Banner from './Banner/Banner';
import Style from './SiteContent.module.scss';
import './SiteContent.css';
import WebForm from '../WebForm/WebForm';
import { Snackbar } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';

class SiteContent extends Component {
    state = {
        applicationItems: [],
        errorHappened: false,
        isSSOError: false,
        statusCode: null,
        searchText: null,
        userInfo: null,
        filter: null,
        isMenuOpen: false,
        categories: null,
        isAdminScreenOpen: false,
        isModalOpen: false,
        bannerConfig: {},
        isTicketCreationFailed: false,
        isTicketCreationSuccessful: false,
        snackBarMessage: '',
        accessRequests: null,
        accessRequestError: false,
        accessRequestApplication: null,
        isTicketCreationLoading: false
    };

    async componentDidMount() {
        if (this.props.accessToken) {
            const axios = Axios.create({
                baseURL: window.env.REACT_APP_BACKEND + '/api/app'
            });
            await this.getUserInformation(axios);
            await this.getApplicationList(axios);
            await this.getCategories(axios);
            await this.getBannerConfig(axios);
            await this.getAccessRequestsInfo(axios);
        }
    };

    getUserInformation = async axios => {
        axios.get(`/getUserInfo/` + this.props.accessToken)
            .then(response => {
                this.setState({ userInfo: response.data });
            }).catch(error => {
                if (error.response) {
                    this.setState({ statusCode: error.response.status });
                }
                this.setState({
                    errorHappened: true,
                    isSSOError: false
                });
            });
    };

    getAccessRequestsInfo = async axios => {
        axios.get(`/getAccessRequestsInfo/` + this.props.accessToken + `?timestamp=` + new Date().getTime())
            .then(response => {
                this.setState({ accessRequests: response.data });
            }).catch(error => {
                this.setState({
                    accessRequests: [],
                    accessRequestError: true
                });
            });
    };

    getApplicationList = async axios => {
        axios.get(`/getUserApplications/` + this.props.accessToken)
            .then(response => {
                this.setState({ applicationItems: response.data });
            }).catch(error => {
                if (error.response) {
                    this.setState({ statusCode: error.response.status });
                }
                this.setState({
                    errorHappened: true,
                    isSSOError: false
                });
            });
    };

    getCategories = async axios => {
        axios.get('/getCategories/' + this.props.accessToken)
            .then(response => {
                this.setState({ categories: response.data });
            }).catch(error => {
                if (error.response) {
                    this.setState({ statusCode: error.response.status });
                }
                this.setState({
                    errorHappened: true,
                    isSSOError: false
                });
            });
    };

    getBannerConfig = async axios => {
        axios.get(`/getBannerConfig`)
            .then(response => {
                this.setState({ bannerConfig: response.data });
            }).catch(error => {
                if (error.response) {
                    this.setState({ statusCode: error.response.status });
                }
                this.setState({
                    errorHappened: true,
                    isSSOError: false
                })
            });
    };

    getApplicationCards = (applications, filter, searchText) => {
        let applicationsCopy = [...applications];
        applicationsCopy.sort((app1, app2) => app1.name.toLowerCase().localeCompare(app2.name.toLowerCase()));
        if (!searchText && filter) {
            applicationsCopy = applicationsCopy.filter(application => filter === application.category.name);
        }
        if (searchText) {
            applicationsCopy = applicationsCopy.filter(application => application.name.toLowerCase().includes(searchText.toLowerCase()));
        }
        return applicationsCopy.map(
            application => {
                if (application.links && application.links.length > 1) {
                    return this.getMultiSelectApplicationCard(application);
                } else {
                    return this.getSingleLinkApplicationCard(application);
                }
            }
        );
    };

    getSingleLinkApplicationCard = application => {
        return (
            <ApplicationCard
                key={application.id}
				applicationId={application.id}
				applicationFileData={application.fileData}
				applicationMimeType={application.fileMimeType}
				applicationName={application.name}
                cardTitle={application.name}
                applicationURL={application.url}
                description={application.description}
                ssoReady={application.isSsoReady}
                isAccessRequestEnabled={application.isAccessRequestEnabled}
                accessRequestApplication={{label: application.name, value: application.jiraFieldValue}}
                buttonText="Login"
                openModal={this.openModal}
                refreshToken={this.refreshAccessToken}
				supportUrl={application.supportUrl}
            >
            </ApplicationCard>
        );
    };

    getMultiSelectApplicationCard = application => {
        return (
            <ApplicationCard
                key={application.id}
				applicationId={application.id}
				applicationFileData={application.file}
				applicationMimeType={application.fileMimeType}
				applicationName={application.name}
                cardTitle={application.name}
                applicationURL={application.url}
                description={application.description}
                ssoReady={application.isSsoReady}
                isAccessRequestEnabled={application.isAccessRequestEnabled}
                accessRequestApplication={{label: application.name, value: application.jiraFieldValue}}
                buttonText="Login"
                openModal={this.openModal}
                refreshToken={this.refreshAccessToken}
                dropDownItems={this.getDropDownButtonForMultiSelect(application.links)}
				supportUrl={application.supportUrl}
				>
            </ApplicationCard>
        );
    };

    refreshAccessToken = () => {
        this.props.refreshToken();
    };

    getDropDownButtonForMultiSelect = links => {
        links.sort((link1, link2) => link1.text.toLowerCase().localeCompare(link2.text.toLowerCase()));
        return links;
    }

    renderApplicationItems() {
        const applications = this.state.applicationItems;
        if (applications && applications.length > 0) {
            return this.getApplicationCards(applications, this.state.filter, this.state.searchText);
        }
        return <Spinner className={Style.SpinnerDiv} animation="border" variant="danger" size="lg" />
    };

    getAccessRequestCards = (accessRequests) => {
        if(accessRequests.length > 0){
            return (
                <div className={Style.AccessRequestsWrapper}>
                    <div className={Style.AccessRequestTitle}>Ongoing Access Requests</div>
                    <div className={Style.AccessRequestTitleBorder}></div>
                    <div>
                        {accessRequests.map(accessRequest => this.getAccessRequestCard(accessRequest))}
                    </div>
                </div>
            )
        } else {
            return (
                <div className={Style.AccessRequestsWrapper}>
                    <div className={Style.AccessRequestTitle}>Ongoing Access Requests</div>
                    <div className={Style.AccessRequestTitleBorder}></div>
                    {this.state.accessRequestError ?
                        <div className={Style.AccessRequestCardEmpty}>Can not retrieve access requests</div>
                        :
                        <div className={Style.AccessRequestCardEmpty}>No ongoing access requests</div>
                    }
                </div>
            )
        }
    };

    getAccessRequestCard = accessRequest => {
        return (
            <div key={accessRequest.id} className={accessRequest.status === 'Approved' || 'Access Requested' || 'Ongoing' ?
				Style.AccessRequestCardApproved : Style.AccessRequestCardEmpty}>
				<div className={Style.AccessRequestCardContent}>
					<label><b>{accessRequest.application}</b></label>
					<div className={Style.AccessRequestStatus}>
						<label className={accessRequest.status === 'Approved' ?
							Style.StatusApproved : accessRequest.status === 'Access Requested' ?
								Style.StatusAccessRequested : Style.StatusOngoing}>
							{accessRequest.status}
						</label>
					</div>
				</div>
                <label>Created: {accessRequest.date}</label><br></br>
                <label>Environment: {accessRequest.environment}</label><br></br>
                <label>BU: {accessRequest.bu}</label><br></br>
            </div>
        );
    };

    renderAccessRequestItems() {
        const accessRequests = this.state.accessRequests;
        if (accessRequests) {
            return this.getAccessRequestCards(accessRequests);
        }
    };

    toggleCollapse = () => {
        this.setState({ isMenuOpen: !this.state.isMenuOpen });
    }

    getFilterButtons = () => {
        const categories = this.state.categories ? [...this.state.categories] : [];
        if (categories) {
            categories.unshift({
                id: 9999,
                name: 'Show all'
            })
            return categories.map(category => (
                <FilterButton
					key={category.id}
					selected={this.checkSelected(category.name)}
					buttonText={category.name}
					filterButtonClick={this.setFilter}
				/>
            ));
        }
    }

    checkSelected = category => {
        return this.state.filter === category || (category === 'Show all' && !this.state.filter);
    }

    setFilter = filteredCategory => {
        this.setState({
            filter: filteredCategory,
            searchText: null
        });
    }

    searchFilter = searchText => {
        this.setState({
            filter: null,
            searchText: searchText
        });
    }

    getBanner = () => {
		return this.state.bannerConfig.enabled ?
            <div className={Style.BannerContainer}>
                <Banner bannerConfig={this.state.bannerConfig} height={'100%'} width={'100%'} />
            </div> : <div></div>
    }

    openModal = (accessRequestApplication) => {
        this.setState({
            isModalOpen: true,
            accessRequestApplication: accessRequestApplication
        });
    };


    closeModal = () => {
        this.setState({
            isModalOpen: false,
            accessRequestApplication: null,
        });
    };

    getWebform = () => {
        const axios = Axios.create({
            baseURL: window.env.REACT_APP_BACKEND + '/api/app'
        });
        return this.state.isModalOpen ?
            <WebForm
                isOpen={this.state.isModalOpen}
                closed={this.closeModal}
                openSnackBar={this.openSnackBar}
                userInfo={this.state.userInfo}
                accessToken={this.props.accessToken}
                displaySSOError={this.displaySSOError}
                toggleLoaderForTicketCreation={this.toggleLoaderForTicketCreation}
                accessRequestApplication={this.state.accessRequestApplication}
                getAccessRequestsInfo={() => this.getAccessRequestsInfo(axios)}/>
            : <div></div>
    };

    openSnackBar = (isSuccess, ticketId) => {
        if (isSuccess) {
            this.setState({
                isTicketCreationSuccessful: true,
                snackBarMessage: this.getSnackBarMessage(ticketId)
            });
        } else {
            this.setState({
                isTicketCreationFailed: true
            });
        }
    }

    closeSnackBar = () => {
        this.setState({
            isTicketCreationFailed: false,
            isTicketCreationSuccessful: false
        });
    }

    getSnackBarMessage = (ticketId) => {
        return 'JIRA ticket with ID: ' + ticketId + ' successfully created!'
    }

    getSnackBar = () => {
        if (this.state.isTicketCreationSuccessful) {
            return (
                <Snackbar
                    open={this.state.isTicketCreationSuccessful}
                    autoHideDuration={5000}
                    onClose={this.closeSnackBar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={this.closeSnackBar} severity="success">
                        {this.state.snackBarMessage}
                    </Alert>
                </Snackbar>
            );
        }
        if (this.state.isTicketCreationFailed) {
            return (
                <Snackbar
                    open={this.state.isTicketCreationFailed}
                    autoHideDuration={5000}
                    onClose={this.closeSnackBar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={this.closeSnackBar} severity="error">
                        Error happened during JIRA ticket creation!
                    </Alert>
                </Snackbar>
            );
        }
    }

    getNormalContent = () => {
        return (
            <div className={Style.UpperRow}>
                {this.getBanner()}
                <div className={Style.ContentStyle}>
                    <div>
                        {this.getSnackBar()}
                    </div>
                    <div className={Style.MaxContentWidth}>
                        <h2 className={Style.WelcomeText}>Welcome to Geopost Connect, your unique access point.</h2>
                    </div>
                    <div className={Style.MaxContentWidth}>
                        <SearchBox
                            changeHandler={this.searchFilter}
                            toggle={this.toggleCollapse}
                            apps={this.state.applicationItems}
                        />
                        <div className={Style.FilterButtons}>
                            {this.getFilterButtons(this.categories)}
                        </div>
                        <Collapse className={Style.Collapse} dimension="height" in={this.state.isMenuOpen}>
                            <div className={Style.CollapseButtons}>
                                {this.getFilterButtons(this.categories)}
                            </div>
                        </Collapse>
                    </div>
                    <div className={Style.ContentItemsDiv}>
                        {this.getWebform()}
                    </div>
                    <div className={Style.MainContainer}>
                        <div className={Style.ContentItemsDiv}>
                            {this.renderApplicationItems()}
                        </div>
						<div className={Style.SideContainer}>
							<div className={Style.NewsletterContainer}>
								<div className={Style.NewsletterHeader}>
									<h3>Newsletter subscription</h3>
								</div>
								<div className={Style.Separator}></div>
								<div className={Style.NewsletterContent}>
									<a href="https://geopostgroup.atlassian.net/servicedesk/customer/portal/77/group/75/create/456">Manage newsletter subscriptions </a> <span className={Style.Arrow}>&gt;</span>
								</div>
							</div>
							<div className={Style.AccessRequestContainer}>
								{this.renderAccessRequestItems()}
							</div>
						</div>
                    </div>
                </div>
            </div>
        );
    };

    toggleAdminScreen = () => {
        const isAdminScreenOpen = this.state.isAdminScreenOpen;
        if (isAdminScreenOpen) {
            const axios = Axios.create({
                baseURL: window.env.REACT_APP_BACKEND + '/api/app'
            });
            this.getApplicationList(axios);
            this.getCategories(axios);
            this.getBannerConfig(axios);
        }
        this.setState({
            isAdminScreenOpen: !isAdminScreenOpen
        });
    };

    getScreen = () => {
        const isAdminScreenOpen = this.state.isAdminScreenOpen;
        if (isAdminScreenOpen) {
            return (
                <Administrative accessToken={this.props.accessToken} />
            );
        }
        return this.getNormalContent();
    };

    content = () => (
        <>
            { this.state.isTicketCreationLoading &&
                <div className={Style.LoadingOverlay}>
                    <Spinner className={Style.OverlaySpinner} animation="border" size="lg" />
                </div>
            }
            <div className={Style.SiteContent}>
                <Header userInfo={this.state.userInfo} toggleAdminScreen={this.toggleAdminScreen} />
                { this.getScreen()}
                <FooterCopyright />
            </div>
        </>
    );

    toggleLoaderForTicketCreation = () => {
        this.setState({
            isTicketCreationLoading: !this.state.isTicketCreationLoading
        })
    }

    displaySSOError = (error) => {
        console.log(error);
        this.setState({
            errorHappened: true,
            isSSOError: true
        })
    };

    errorContent = () => (
        <div className={Style.ErrorStyle}>
            <DpdModal show={this.state.errorHappened} modalText={this.getModalText()}></DpdModal>
            <FooterCopyright />
        </div>
    );

    getModalText = () => (
        <>
            <span>
                We're sorry,<br></br>
                {this.state.isSSOError ? 'but you have been logged out from SSO, click Login to reconnect.'
                : 'but something went wrong while getting the applicaiton list, try to log in or check application list.'
                }
            </span>
            {this.state.statusCode ? <span><br></br>Error code: {this.state.statusCode}</span> : <></>}
        </>
    );

    render() {
        if (this.state.errorHappened) {
            return (this.errorContent());
        }
        return (this.content());
    };
}

export default SiteContent;