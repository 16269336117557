import React, { Component } from 'react';
import LinkItem from '../LinkItem/LinkItem';
import Style from './LinkItems.module.scss';

class LinkItems extends Component {
    getLinks = (linksArray) => {
        const linkItems = linksArray.map(link => {
            return <LinkItem text={ link.text } url={ link.url } key={ link.id } />
        });
        return linkItems;
    }

    render() {
        return (
            <div className={ Style.LinkHolder }>
                { this.getLinks(this.props.items) }
            </div>
        );
    };
}

export default LinkItems;