import React, { Component } from 'react';
import Style from './Header.module.scss';
import headerLogo from '../../../assets/img/geopost_header_logo.png';
import adminButton from '../../../assets/img/Settings_blackred_pos_rgb.png';
import AlertBar from '../SiteContent/AlertBar/AlertBar';

class Header extends Component {

	handleLogout = () => {
		window.location = window.env.REACT_APP_SSO + '/auth/realms/DPD/protocol/openid-connect/logout?redirect_uri=' + window.env.REACT_APP_URL;
	};

	getUserGreeting = () => {
		if (this.props.userInfo) {
			return this.props.userInfo.name;
		}
		return 'username not found';
	};

	getAdminScreenButton = () => {
		const userInfo = this.props.userInfo;
		if (userInfo && userInfo.admin) {
			return (
				<div className={Style.AdminButton} onClick={this.props.toggleAdminScreen}>
					<img src={adminButton} alt='Admin screen'></img>
				</div>
			);
		}
	};

	render() {
		return (
			<div className={Style.HeaderContainer}>
				<div className={Style.HeaderStyle}>
					<div className={Style.HeaderLeft}>
						<img src={headerLogo} alt='DPD Logo'></img>
						{this.getAdminScreenButton()}
					</div>
					<div className={Style.HeaderRight}>
						<div className={Style.UserName}>
							{this.getUserGreeting()}
						</div>
						<div className={Style.HeaderSpacer}>
							|
						</div>
						<div className={Style.LogoutLink} onClick={this.handleLogout}>
							Logout
						</div>
					</div>
				</div>
				<div><AlertBar /></div>
			</div>
		);
	}
}

export default Header;