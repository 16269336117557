import { Chip, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Axios from 'axios';
import React, { Component } from 'react';
import TextBox from '../TextBox/TextBox';
import './BusinessUnitTool.css';

class BusinessUnitTool extends Component {
    state = {
        name: null,
        buApplications: [],
        allApplications: [],
        errorList: []
    };

    async componentDidMount() {
        const response = await this.getAxiosInstance().get(`/getApplicationList/` + this.props.acessToken);
        const bu = this.props.edit;
        if (bu) {
            this.setState({
                allApplications: response.data,
                name: bu.name,
                buApplications: [...bu.applicationIdList]
            });
        } else {
            this.setState({
                allApplications: response.data
            });
        }
    };

    getAxiosInstance = () => {
        return Axios.create({
            baseURL: window.env.REACT_APP_BACKEND + '/api/app'
        });
    };

    handleNameChange = (event) => {
        this.setState({
            name: event.target.value
        });
    };

    getApplicationNames = () => {
        return this.state.allApplications.map(application => application.name);
    };

    handleSelectionChange = (event, value, reason) => {
        this.setState({
            buApplications: this.state.allApplications.filter(app => value.includes(app.name)).map(app => app.id)
        });
    };

    handleSaveOnClick = async () => {
        const errorList = this.getErrorList();
        if (errorList.length === 0) {
            try {
                const selectedApplications = [...this.state.buApplications];
                const allApplications = [...this.state.allApplications];
                const payload = allApplications.filter(app => selectedApplications.includes(app.id)).map(app => this.createBuObject(app.id));
                await this.getAxiosInstance().post(`/addBusinessUnit/` + this.props.acessToken, payload);
                this.props.cancel();
            } catch (error) {
                console.log(error);
            }
        } else {
            this.setState({
                errorList: errorList
            });
        }
    };

    getErrorList = () => {
        let errorList = [];
        if (this.isEmptyString(this.state.name)) {
            errorList.push('Business Unit name cannot be empty!');
        }
        if (this.state.buApplications.length === 0) {
            errorList.push('Business Unit need to have at least one application!');
        }
        return errorList;
    };

    isEmptyString = (string) => {
        return (!string || string.trim().length === 0);
    };

    createBuObject = (id) => {
        return {
            name: this.state.name,
            applicationId: id
        };
    };

    getDefaultValues = () => {
        const bu = this.props.edit;
        if (bu) {
            return [...this.state.allApplications].filter(app => bu.applicationIdList.includes(app.id)).map(app => app.name);
        }
        return [];
    };

    getErrorMessages = () => {
        const errorList = this.state.errorList ? [...this.state.errorList] : [];
        let counter = 0;
        return errorList.map(error => {
            return (
                <div key={ counter++ }>{ error }</div>
            );
        })
    };

    render() {
        return (
            <div>
                <div className="title-holder">
                    <h4>
                        { this.props.edit ? 'Edit Business Unit' : 'Add Business Unit' }
                    </h4>
                </div>
                <div>
                    <TextBox 
                        placeHolder="BU Name"
                        onChange={ this.handleNameChange }
                        value={ this.state.name || '' }
                    />
                </div>
                <div>
                     <Autocomplete
                        multiple
                        id="tags-filled"
                        options={ this.getApplicationNames() }
                        freeSolo
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField {...params} variant="filled" label="Application List" placeholder="Applications" />
                        )}
                        defaultValue={ this.props.applicationNames ? this.props.applicationNames : [] }
                        onChange={ this.handleSelectionChange }
                    />
                </div>
                <div className="button-holder">
                    <div className="main-button" onClick={ this.handleSaveOnClick }>
                        { this.props.edit ? 'Edit Business Unit' : 'Add Business Unit' }
                    </div>
                    <div className="grey-button" onClick={ this.props.cancel }>
                        Cancel
                    </div>
                </div>
                <div className="error-holder">
                    { this.getErrorMessages() }
                </div>
            </div>
        );
    };
}

export default BusinessUnitTool;