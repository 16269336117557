import React, { Component } from 'react';
import Style from './FilterButton.module.scss'

class FilterButton extends Component {

	handleOnClick = () => {
		if (this.props.buttonText === 'Show all' || this.props.selected) {
			this.props.filterButtonClick(null);
		} else {
			this.props.filterButtonClick(this.props.buttonText);
		}
	}

    getStyles = () => {
        if (this.props.selected) {
            return Style.ButtonSelected.concat(' ').concat(Style.FilterCommon);
        }
        return Style.FilterButton.concat(' ').concat(Style.FilterCommon);
    }

    render() {
        return (
            <div className={ this.getStyles() } onClick={ this.handleOnClick }>
                { this.props.buttonText}
            </div>
        );
    }
}

export default FilterButton;