import React from 'react';
import { Component } from 'react';
import TextBox from '../TextBox/TextBox';
import './MultiLink.css';

class MultiLink extends Component {
    state = {
        links: [
            {
                id: null,
                text: null,
                url: null,
                applicationId: null
            },
            {
                id: null,
                text: null,
                url: null,
                applicationId: null
            }
        ]
    };

    componentDidMount() {
        const links = this.props.links;
        if (links) {
            this.setState({
                links: this.props.links
            })
        }
    };

    getLinksEditor = () => {
        const links = this.state.links;
        const linkEditors = [];
        for (let i = 0; i < links.length; i++) {
            linkEditors.push(this.getSingleLinkEditor(i, links[i]));
        }
        return linkEditors;
    };

    handleTextBoxInputChange = (event, fieldName, index) => {
        let links = this.state.links;
        let link = this.state.links[index];
        link = {
            ...link,
            [fieldName]: event.target.value
        }
        links[index] = link;
        this.setState(
            {
                links: links
            }
        );
        this.props.onChange(links);
    };

    getSingleLinkEditor = (index, link) => {
        return (
            <div key={ index } className="link-holder">
                <TextBox 
                    placeHolder="Link Name"
                    onChange={ (event) => this.handleTextBoxInputChange(event, "text", index) }
                    value={ this.state.links[index].text || '' }
                />
                <TextBox 
                    placeHolder="Application Url"
                    onSelect={ this.handleTextBoxInputChange }
                    onChange={ (event) => this.handleTextBoxInputChange(event, "url", index) }
                    value={ this.state.links[index].url || '' }
                />
                { this.getDeleteLinkButton(index) }
            </div>
        );
    };

    isEmptyLink = (index) => {
        const link = this.state.links[index];
        return link.text && link.url;
    };

    getDeleteLinkButton = (index) => {
        if (this.state.links.length > 1) {
            return (
                <div className="delete-link-button-holder">
                    <div className="delete-link-button" onClick={ () => this.handleDeleteLinkClick(index) } >
                        X
                    </div>
                </div>
            );
        }
    };

    handleDeleteLinkClick = (index) => {
        let newLinks = [...this.state.links];
        newLinks.splice(index, 1);
        this.setState({
            links: newLinks
        });
        this.props.onChange(newLinks)
    };

    addNewLink = () => {
        let newLinks = [...this.state.links];
        newLinks.push(this.getEmpyLinkObject());
        this.setState({
            links: newLinks
        });
    };

    getEmpyLinkObject = () => {
        return {
            id: null,
            text: null,
            url: null,
            applicationId: null
        };
    };

    render() {
        return (
            <div className="multilink-holder">
                <div>
                    Application links:
                </div>
                { this.getLinksEditor() }
                <div className="add-link-button" onClick={ this.addNewLink }>
                    +
                </div>
            </div>
        );
    };
}

export default MultiLink;