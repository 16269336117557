import React, { useState } from 'react';
import Style from './AlertBar.module.scss';

const AlertBar = () => {
	const [visible, setVisible] = useState(true);
	const newUrl = 'https://connect.geopost.com/#';

	const handleClose = () => {
		setVisible(false);
	};

	if (!visible) {
		return null;
	}

	return (
		<div className={Style.AlertBar}>
            <span className={Style.AlertMessage}>
                Please note: URL changed from
                <a href='https://connect.dpdgroup.com/#'> https://connect.dpdgroup.com/# </a>
                 to
                <a href={newUrl}> {newUrl}</a>
            </span>
			<button className={Style.AlertClose} onClick={handleClose}>X</button>
		</div>
	);
};

export default AlertBar;
