import React, { Component } from 'react';
import Style from './AccessRequestIcon.module.scss';
import AccessRequestPicture from '../../../../../assets/pictoset/transparent_access_request_icon.png';

class AccessRequestIcon extends Component {
    render() {
        if (this.props.isAccessRequestEnabled) {
                return (
                <div className={ Style.AccessRequestIcon } onClick={this.openAccessRequestModalForApplication}>
                    <div>
                        <div>
                            <img src={ AccessRequestPicture } alt="Access Request" className={ Style.AccessRequestPictureClass }></img>
                        </div>
                        <div>
                            <span>Access Request</span>
                        </div>
                    </div>
                </div>
            );
        }
        return <div className={ Style.AccessRequestIcon }></div>;
    };

    openAccessRequestModalForApplication = () => {
        this.props.openModal(this.props.accessRequestApplication);
    }
}

export default AccessRequestIcon;