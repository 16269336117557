import React, { Component } from 'react';
import { TextField, FormControlLabel, Checkbox } from "@material-ui/core";
import Axios from 'axios';
import Banner from '../../SiteContent/Banner/Banner'
import Style from './BannerTool.module.scss';
import './BannerTool.css';


class BannerTool extends Component {

	state = {
		id: '',
		file: null,
		redirectUrl: '',
		enabled: true,
		errorList: []
	}

	async componentDidMount() {
		const response = await this.getAxiosInstance().get(`/getBannerConfig`);
		this.setState({
			id: response.data.id,
			file: response.data.file,
			redirectUrl: response.data.redirectUrl,
			enabled: response.data.enabled
		});
	};

	getAxiosInstance = () => {
		return Axios.create({
			baseURL: window.env.REACT_APP_BACKEND + '/api/app'
		});
	};

	saveBannerConfig = async () => {
		const bannerFormData = this.createBannerFormData();
		const errorList = [...this.getErrorList(bannerFormData)];
		if (errorList.length === 0) {
			try {
				await this.getAxiosInstance().post('/updateBannerConfig', bannerFormData);
				this.props.cancel();
			} catch (error) {
				console.log(error);
			}
		} else {
			this.setState({
				errorList: errorList
			});
		}
	};

	createBannerFormData = () => {
		const formData = new FormData();
		const file = this.state.file;
		formData.append("id", this.state.id);
		if('File' in window && file instanceof File){
			formData.append("multipartFile", file, file.name);
		}
		formData.append("redirectUrl", this.state.redirectUrl);
		formData.append("enabled", this.state.enabled);
		return formData;
	};

	getErrorList = (bannerFormData) => {
		let errorList = [];
		const file = this.state.file;
		if (!file && (bannerFormData.get('multipartFile') === null || bannerFormData.get('multipartFile').length <= 0)) {
			errorList.push('Banner file is required!');
		}
		if (this.isEmptyString(bannerFormData.get('redirectUrl'))) {
			errorList.push('Banner redirect url can not be empty!');
		}
		return errorList;
	};

	getErrorMessages = () => {
		const errorList = this.state.errorList ? [...this.state.errorList] : [];
		let counter = 0;
		return errorList.map(error => {
			return (
				<div key={counter++}>{error}</div>
			);
		})
	};

	isEmptyString = (string) => {
		return (!string || string.trim().length === 0);
	};

	saveRedirectUrl = (event) => {
		this.setState({ redirectUrl: event.target.value })
	};

	saveEnabled = (event) => {
		this.setState({ enabled: event.target.checked })
	};

	onFileChange = event => {
		this.setState({ file: event.target.files[0] });
	};

	renderActionButtons = () => (
		<div className="actions-holder">
			<div className="main-button" onClick={this.props.cancel}>Go Back</div>
			<div className="grey-button" onClick={this.saveBannerConfig}>Save Banner Config</div>
		</div>
	);

	render() {
		return (
			<div className={Style.BannerHolder}>
				<div className="title-holder">
					<h4>Manage Banner</h4>
				</div>
				{this.renderActionButtons}
				<div className={Style.ErrorHolder}>
					{this.getErrorMessages()}
				</div>
				<div className={Style.BannerFieldHolder}>
					<div>File</div>
					<div>
						<input type="file" onChange={this.onFileChange}></input>
					</div>
					<TextField
						id="standard-basic"
						label="Redirect Url"
						variant="filled"
						value={this.state.redirectUrl}
						onChange={this.saveRedirectUrl}
						className={Style.BannerConfigTextField}
					/>
					<FormControlLabel label="Enabled" control={
						<Checkbox
							className={Style.BannerCheckbox}
							checked={this.state.enabled}
							onChange={this.saveEnabled}
						/>
					}/>
				</div>
				<div className={Style.BannerContainer}>
					<div className={Style.BannerPreview}>Preview</div>
					<Banner bannerConfig={{
						file: this.state.file,
						redirectUrl: this.state.redirectUrl
					}}/>
				</div>
				{this.renderActionButtons()}
			</div>);
	}
}

export default BannerTool;