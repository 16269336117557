import React, { Component } from 'react';
import Style from './ApplicationCard.module.scss';
import MultiLink from './MultiLink/MultiLink';
import SingleLink from './SingleLink/SingleLink';
import SSOLogo from './SSOLogo/SSOLogo';
import AccessRequestIcon from './AccessRequestIcon/AccessRequestIcon';
import InfoIcon from '../../../../assets/pictoset/Info_icon.svg';
import SupportIcon from '../../../../assets/pictoset/Support_icon.svg';
import Axios from 'axios';


const getAxiosInstance = () => {
	return Axios.create({
		baseURL: window.env.REACT_APP_BACKEND + '/api/app'
	});
};

function truncateText(text, maxLength) {
	if (text.length <= maxLength) {
		return text;
	}
	return text.substring(0, maxLength) + '...';
}

class ApplicationCard extends Component {

	handleDownloadClick = async () => {
		const { applicationId, applicationMimeType, applicationName, applicationFileData } = this.props;
		if (!applicationId || !applicationFileData) {
			console.error("Application ID or file data is not defined. There is no file to download.");
			return;
		}

		try {
			const response = await getAxiosInstance().get(`/downloadFile/${applicationId}`, {
				responseType: 'blob'
			});

			let fileExtension;
			switch (applicationMimeType) {
				case "application/pdf":
					fileExtension = ".pdf";
					break;
				case "application/vnd.ms-powerpoint":
					fileExtension = ".ppt";
					break;
				case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
					fileExtension = ".pptx";
					break;
				default:
					fileExtension = "";
			}

			const url = window.URL.createObjectURL(new Blob([response.data], { type: applicationMimeType }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${applicationName}${fileExtension}`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (error) {
			console.error("Error downloading file:", error);
		}
	};

	handleButtonClick = () => {
		window.open(this.props.applicationURL, '', 'noopener,noreferrer,');
	};

	getAccessRequestIcon() {
		const { isAccessRequestEnabled, accessRequestApplication, openModal } = this.props;
		if (isAccessRequestEnabled && accessRequestApplication && openModal) {
			return (
				<div className={Style.BottomSection}>
					<div className={Style.SplitContent}>
						<div className={Style.LeftSplit}>
							<AccessRequestIcon
								isAccessRequestEnabled={isAccessRequestEnabled}
								accessRequestApplication={accessRequestApplication}
								openModal={openModal}
							/>
						</div>
					</div>
				</div>
			);
		}
		return null;
	}

	getSupport() {
		const { supportUrl } = this.props;

		if (!supportUrl) {
			return null;
		}

		const handleSupportClick = () => {
			window.open(supportUrl, '_blank', 'noopener,noreferrer');
		};

		return (
			<div className={Style.BottomSection}>
				<div className={Style.SplitContent} onClick={handleSupportClick} style={{ cursor: 'pointer' }}>
					<div className={Style.LeftSplit}>
						<img src={SupportIcon} alt={'Support icon'} className={Style.SupportIcon} />
						<span className={Style.SupportText}>Support</span>
					</div>
				</div>
			</div>
		);
	}

	getLinks() {
		const { buttonText, dropDownItems, refreshToken, ssoReady } = this.props;

		if (buttonText || ssoReady) {
			return (
				<div className={Style.BottomSection}>
					<div className={Style.SplitContent}>
						{buttonText && (
							<div className={Style.LeftSplit}>
								{dropDownItems && dropDownItems.length > 1 ? (
									<MultiLink
										text={buttonText}
										dropDownItems={dropDownItems}
										refreshToken={refreshToken}
									/>
								) : (
									<SingleLink
										linkText={buttonText}
										onClickEvent={this.handleButtonClick}
									/>
								)}
							</div>
						)}
						<div className={Style.RightSplit}>
							<SSOLogo ssoReady={ssoReady} />
						</div>
					</div>
				</div>
			);
		}
		return null;
	}

	getCardContent() {
		const { cardTitle, description, applicationFileData } = this.props;
		const maxLength = 150;
		const truncatedDescription = truncateText(description, maxLength);

		return (
			<div className={Style.CardStyle}>
				<div className={Style.CardTitle}>
					<div className={Style.TitleText}>
						{cardTitle}
					</div>
					{applicationFileData && (
					<div
						className={Style.TitleIcon}
						onClick={applicationFileData ? this.handleDownloadClick : null}
						role="button"
						tabIndex={0}
						style={{ cursor: applicationFileData ? 'pointer' : 'default' }}
					>
						<img src={InfoIcon} className={Style.Info_picture} alt={'Info logo'} />
						<span>Info</span>
					</div>
					)}
				</div>
				<div className={Style.CardText}>
					{truncatedDescription}
				</div>
				<div className={Style.CardBottom}>
					{this.getAccessRequestIcon()}
					{this.getSupport()}
					{this.getLinks()}
				</div>
			</div>
		);
	}

	render() {
		return this.getCardContent();
	}
}

export default ApplicationCard;