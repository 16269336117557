import React, {Component} from 'react';
import ReactPlayer from 'react-player';
import Style from './Banner.module.scss';

class Banner extends Component {
	state = {
		file: null
	}

	redirect = () => {
		window.location = this.props.bannerConfig.redirectUrl
	}

	componentDidMount() {
		const bannerConfig = this.props.bannerConfig;
		if (bannerConfig) {
			this.setState({
				file: 'data:video/mp4;base64,' + bannerConfig.file
			});
		}
	}

	componentDidUpdate(prevPros, prevState) {
		const oldFile = prevPros.bannerConfig.file;
		const newFile = this.props.bannerConfig.file;
		if (newFile && oldFile !== newFile) {
			try {
				this.setState({
					file: URL.createObjectURL(newFile)
				});
			} catch (error) {
				this.setState({
					file: 'data:video/mp4;base64,' + newFile
				});
			}

		}
	};

	render() {
		const height = this.props.height ? this.props.height : 150;
		const width = this.props.width ? this.props.width : 640;

		return (
			<div className={Style.BannerPicture} onClick={this.redirect}>
				<ReactPlayer 
					url={this.state.file} 
					playing={true} 
					loop={true}
					height={height}
					width={width}
					muted={true}
				/>
			</div>
		);
	}
}

export default Banner;