import React, { Component, createRef } from 'react';
import './TextBox.css';

class TextBox extends Component {
    constructor(props) {
        super(props);

        this.inputRef = createRef();

        this.state = {
            isEditable: false
        };
    };

    handleOnClick = () => {
        this.setState({ 
            isEditable: true 
        });
    };

    handleFocusLost = () => {
        if (this.inputRef.current.value === '') {
            this.setState({ isEditable: false });
        }
    };

    getEditableTextBox = () => {
        return (
            <div className="textbox">
                <div className="textbox-description">
                    { this.props.placeHolder }
                </div>
                <div className="textbox-input-holder">
                    <input 
                        className="textbox-input-focus"
                        type="search"
                        onChange={ this.props.onChange }
                        value={ this.props.value }
                        onBlur={ this.handleFocusLost }
                        ref={ this.inputRef }
                    />
                </div>
            </div>
        );
    };

    getEditableTextBoxWithFocus = () => {
        return (
            <div className="textbox">
                <div className="textbox-description">
                    { this.props.placeHolder }
                </div>
                <div className="textbox-input-holder">
                    <input 
                        className="textbox-input-focus"
                        type="search"
                        onChange={ this.props.onChange }
                        value={ this.props.value }
                        onBlur={ this.handleFocusLost }
                        ref={ this.inputRef }
                        autoFocus
                    />
                </div>
            </div>
        );
    };

    getPlaceHolderTextBox = () => {
        return (
            <div className="textbox" onClick={ this.handleOnClick }>
                <div className="textbox-placeholder">
                    { this.props.placeHolder }
                </div>
            </div>
        );
    };

    render() {
        if (this.props.value) {
            return this.getEditableTextBox();
        }
        if (this.state.isEditable) {
            return this.getEditableTextBoxWithFocus();
        }
        return this.getPlaceHolderTextBox();
    };
}

export default TextBox;