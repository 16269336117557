import React, { Component, createRef } from 'react';
import './DropDown.css';
import LeftArrow from '../../../../assets/pictoset/arrow_small_white_right.svg';
import { ClickAwayListener, Popper } from '@material-ui/core';

class DropDown extends Component {
    constructor(props) {
        super(props);
        this.dropDownRef = createRef();
        this.state = {
            isListOpen: false
        };
    };

    handleOnClick = () => {
        this.setState(prevState => ({
            isListOpen: !prevState.isListOpen,
        }));
    };

    handleOnClickAway = () => {
        this.setState({ isListOpen: false });
    };

    handleOnClickSelected = (element) => {
        this.setState({
            isListOpen: false 
        });
        if (this.props.onChange) {
            this.props.onChange(element);
        }
    };

	getDropDownItems = (items) => {
		return items.map(element => (
			<div className="dropdown-list-item-holder" key={element.id}>
				<div className="dropdown-list-item" onClick={() => this.handleOnClickSelected(element)}>
					{element.name}
				</div>
			</div>
		));
	};

    getEmptyDropDown = (items) => {
        return (
            <div>
                <div className="dropdown-holder" onClick={ this.handleOnClick } ref={ this.dropDownRef }>
                    <div className={ this.state.isListOpen || this.state.value ? 'input-holder-open' : 'input-holder' }>
                        { this.props.placeHolder }
                    </div>
                    <div className="image-holder" >
                        <img className={ this.state.isListOpen ? 'arrow-up' : 'arrow-down' } src={ LeftArrow } alt="arrow" />
                    </div>
                    <Popper open={ this.state.isListOpen } anchorEl={ this.dropDownRef.current }>
                        <div className="dropdown-list">
                            { this.getDropDownItems(items) }
                        </div>
                    </Popper>
                </div>
            </div>
        );
    };

    getDropDownWithValue = (items) => {
        return (
            <div>
                <div className="dropdown-holder dropdown-holder-filled" onClick={ this.handleOnClick } ref={ this.dropDownRef }>
                    <div className="dropdown-filled">
                        <div className="dropdown-title">
                            { this.props.placeHolder }
                        </div>
                        <div className={ this.state.isListOpen || this.state.value ? 'input-holder-open' : 'input-holder' }>
                            { this.props.value.name }
                        </div>
                    </div>
                    <div className="image-holder-filled" >
                        <img className={ this.state.isListOpen ? 'arrow-up' : 'arrow-down' } src={ LeftArrow } alt="arrow" />
                    </div>
                    <Popper open={ this.state.isListOpen } anchorEl={ this.dropDownRef.current }>
                        <div className="dropdown-list">
                            { this.getDropDownItems(items) }
                        </div>
                    </Popper>
                </div>
            </div>
        );
    };

    render() {
        const items = this.props.items;
        return(
            <ClickAwayListener onClickAway={ this.handleOnClickAway }>
                { this.props.value ? this.getDropDownWithValue(items) : this.getEmptyDropDown(items) }
            </ClickAwayListener>
        );
    };
}

export default DropDown;