import { ClickAwayListener, Popper } from '@material-ui/core';
import React, { Component, createRef } from 'react';
import SingleLink from '../SingleLink/SingleLink';
import LinkItems from './LinkItems/LinkItems';
import Style from './MultiLink.module.scss';

class MultiLink extends Component {
    constructor(props) {
        super(props);

        this.linkRef = createRef();

        this.state = {
            isPopperOpen: false
        }
    }

    handleClickAway = () => {
        this.setState({ isPopperOpen: false });
    };

    handleClick = (event) => {
        const isPopperOpen = this.state.isPopperOpen;
        this.setState({ 
            isPopperOpen: !isPopperOpen
        });
    };

    render() {
        return (
            <ClickAwayListener onClickAway={ this.handleClickAway }>
                <div className={ Style.Link } onClick={ this.handleClick } ref={ this.linkRef }>
                        <SingleLink linkText={ this.props.text } />
                        <Popper open={ this.state.isPopperOpen } anchorEl={ this.linkRef.current }>
                            <LinkItems items={ this.props.dropDownItems } refreshToken={ this.props.refreshToken } />
                        </Popper>
                </div>
            </ClickAwayListener>
        );
    };
}

export default MultiLink;