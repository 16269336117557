import Axios from 'axios';
import React, { Component } from 'react';
import TextBox from '../TextBox/TextBox';
import './CategoryTool.css';

class CategoryTool extends Component {
    state = {
        id: null,
        name: null
    };

    componentDidMount() {
        if (this.props.edit) {
            this.setState({
                id: this.props.edit.id,
                name: this.props.edit.name
            });
        }
    };

    handleNameChange = (event) => {
        this.setState({
            name: event.target.value
        });
    };

    handleSaveOnClick = async () => {
        try {
            const payload = {
                id: this.state.id,
                name: this.state.name
            };
            await this.getAxiosInstance().post(`/addCategory/` + this.props.accessToken, payload);
            this.props.cancel();
        } catch (error) {
            console.log(error);
        }
    };

    getAxiosInstance = () => {
        return Axios.create({
            baseURL: window.env.REACT_APP_BACKEND + '/api/app'
        });
    };

    render() {
        return (
            <div>
                <div className="title-holder">
                    <h4>
                        { this.props.edit ? 'Edit Category' : 'Add Category' }
                    </h4>
                </div>
                <div>
                    <TextBox 
                        placeHolder="Category Name"
                        onChange={ this.handleNameChange }
                        value={ this.state.name || '' }
                        />
                </div>
                <div className="button-holder">
                    <div className="main-button" onClick={ this.handleSaveOnClick }>
                        { this.props.edit ? 'Edit Category' : 'Add Category' }
                    </div>
                    <div className="grey-button" onClick={ this.props.cancel }>
                        Cancel
                    </div>
                </div>
            </div>
        );
    };
}

export default CategoryTool;