import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Styles from './DpdModal.module.scss';

class DpdModal extends Component {
	state = {
		showModal: this.props.show
	};

	handleModalClose = () => {
		this.setState({ showModal: !this.state.showModal });
		window.location = window.location.origin;
	};

	render() {
		return (
			<Modal
				show={this.state.showModal}
				onHide={this.handleModalClose}
				backdrop='static'
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>Error</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{this.props.modalText}
				</Modal.Body>
				<Modal.Footer>
					<Button className={Styles.LoginButton} variant='danger' onClick={this.handleModalClose}>
						Login
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default DpdModal;