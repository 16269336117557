import { Dialog, TextField, withStyles } from '@material-ui/core';
import React, { Component } from "react";
import Axios from 'axios';
import Select from 'react-select';
import Style from './WebForm.module.scss';
import  './WebForm.css';
import Dropzone from 'react-dropzone';


const styles = {


	dialogPaper: {
		borderRadius: '20px',
	},
	input: {
		fontWeight: '600',
        fontFamily: 'PlutoSansDPDLight',
	},
	label: {
		fontWeight: '600',
        fontFamily: 'PlutoSansDPDLight',
	},
	textField: {
		marginBottom: '15px',
        fontFamily: 'PlutoSansDPDLight',
	},
};

class WebForm extends Component {
	state = {
		summary: '',
		description: '',
		selectedBusinessUnits: [],
		selectedApplication: '',
		selectedEnvironments: [],
		attachedFiles: [],
		businessUnitOptions: [],
		applicationOptions: [],
		environmentOptions: [],
		businessUnitFieldId: '',
		applicationFieldId: '',
		environmentFieldId: '',
		errorList: [],
		issueTypeId: '',
		projectKey: '',
		isLoading: false
	};

	componentDidMount() {
		this.loadJiraWebFormData();
	}

    getAxiosInstance = () => {
        return Axios.create({
            baseURL: window.env.REACT_APP_BACKEND + '/api/app'
        });
    };

	getInformation = async (endpoint) => {
        try {
            const response = await this.getAxiosInstance().get('' + endpoint + this.props.accessToken);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    };

	getApplicationList = async () => {
		let url;
		let applicationList = [];
		if (this.props.userInfo.admin) {
			url = '/getApplicationList/';
		} else {
			url = '/getUserApplications/';
		}
        await this.getAxiosInstance().get(url + this.props.accessToken)
            .then(response => {
                applicationList = response.data;
            }).catch(error => {
                this.props.displaySSOError(error)
            });
		return applicationList;
    };

	loadJiraWebFormData = async () => {
		const jiraMetaData = await this.getAxiosInstance().get("/getJiraMetaData");
		const applicationList = await this.getApplicationList();

		let jiraBusinessUnitData = {};
		let jiraApplicationData = {};
		let jiraEnvironmentData = {};
		let issueTypeId;
		let projectKey;

		Object.entries(jiraMetaData.data.projects[0].issuetypes[0].fields).forEach(([key, jiraDataValue])=> {
			const jiraDataName = jiraDataValue.name;
			if(jiraDataName === 'SSOIN Business Unit'){
				jiraBusinessUnitData = jiraDataValue
			} else if(jiraDataName === 'SSOIN Application'){
				jiraApplicationData = jiraDataValue
			} else if(jiraDataName === 'SSOIN Environment'){
				jiraEnvironmentData = jiraDataValue
			} else if(jiraDataName === 'Issue Type'){
				if(jiraDataValue.allowedValues && jiraDataValue.allowedValues.length){
					issueTypeId = jiraDataValue.allowedValues[0].id
				}
			} else if(jiraDataName === 'Project'){
				if(jiraDataValue.allowedValues && jiraDataValue.allowedValues.length){
					projectKey = jiraDataValue.allowedValues[0].key
				}
			}
		});

		this.setState({
			projectKey: projectKey,
			issueTypeId: issueTypeId,
			businessUnitOptions: [...jiraBusinessUnitData.allowedValues.map(bu => { return {value: bu.value, label: bu.value}})],
			applicationOptions: [...applicationList.map(app => { return {value: app.jiraFieldValue, label: app.name}})],
			environmentOptions: [...jiraEnvironmentData.allowedValues.map(env => { return {value: env.value, label: env.value}})],
			businessUnitFieldId: jiraBusinessUnitData.key,
			applicationFieldId: jiraApplicationData.key,
			environmentFieldId: jiraEnvironmentData.key,
			selectedApplication: {"value" : this.props.accessRequestApplication.value}
		});
	}

	saveSummary = (event) => {
		this.setState({summary: event.target.value})
	};

	saveDescription = (event) => {
		this.setState({description: event.target.value})
	};

	saveBusinessUnit = (values) => {
		this.setState({selectedBusinessUnits: values})
	};

	saveEnvironment = (values) => {
		this.setState({selectedEnvironments: values})
	};

	setLoading = (value) => {
		this.props.toggleLoaderForTicketCreation();
		this.setState({isLoading: value})
	}

	createJiraTicket = async () => {
		const jiraTicketJsonData = await this.createJiraTicketJsonData();
		const errorList = [...this.getErrorList(jiraTicketJsonData)];
		if (errorList.length === 0 && !this.state.isLoading) {
			const jiraTicketFormData = this.createJiraTicketFormData(jiraTicketJsonData, this.state.attachedFiles);
			const requestorEmail = this.props.userInfo.email;
			const accessRequestApplication = this.props.accessRequestApplication.label;
			this.getAxiosInstance().post(`/createJiraTicket/${requestorEmail}/${accessRequestApplication}`, jiraTicketFormData)
				.then(response => {
					this.props.openSnackBar(true, response.data)
				})
				.catch(error => {
					this.props.openSnackBar(false)
					console.log(error);
				});
			this.props.closed();
		} else {
			this.setState({
				errorList: errorList
			});
		}
	};

	getReporterUserName = async (userInfo) => {
		let reporterUserName;
		await this.getAxiosInstance().get("/getJiraUser/" + userInfo.email)
            .then(response => {
                reporterUserName = response.data;
            }).catch(error => {
                console.log(error)
            });
		return reporterUserName;
	}

	createJiraTicketFormData = (jiraTicketJsonData, attachedFiles) => {
		const formData = new FormData();
		formData.append("jiraTicketData", JSON.stringify(jiraTicketJsonData));
		for(let file of attachedFiles){
			formData.append("multipartFiles", file, file.name);
		}
		return formData;
	};


	createJiraTicketJsonData = async () => {
		const reporterUserName = await this.getReporterUserName(this.props.userInfo);
		let jiraTicketData = {
			"fields":
			{
				"project":
				{
					"key": this.state.projectKey
				},
				"summary": this.state.summary,
				"description": this.state.description + this.getTicketCreatorInfo(),
				"issuetype":
				{
					"id": this.state.issueTypeId
				}
			}
		}
		if(reporterUserName){
			jiraTicketData.fields["reporter"] = {"name" : reporterUserName};
		}
		if(this.state.selectedBusinessUnits.length){
			jiraTicketData.fields[this.state.businessUnitFieldId] = this.state.selectedBusinessUnits.map(bu => ({"value" : bu.value}));
		}
		if(this.state.selectedApplication){
			jiraTicketData.fields[this.state.applicationFieldId] = {"value" : this.state.selectedApplication.value};
		}
		if(this.state.selectedEnvironments.length){
			jiraTicketData.fields[this.state.environmentFieldId] = this.state.selectedEnvironments.map(env => ({"value" : env.value}));
		}
		return jiraTicketData;
	};

	getTicketCreatorInfo = () => {
		return "\n\n<-- Auto ticket generated -->\nCreated by: "+ this.props.userInfo.name +", " + this.props.userInfo.email
	}

	getErrorList = (jiraTicketData) => {
		let errorList = [];
		if (this.isEmptyString(jiraTicketData.fields.summary)) {
			errorList.push('Please provide a valid value for field \'Summary\'');
		} else if(jiraTicketData.fields.summary.length >= 255){
			errorList.push('\'Summary\' must be less than 255 characters.');
		}
		if (this.isEmptyString(jiraTicketData.fields.description)) {
			errorList.push('Please provide a valid value for field \'Description\'');
		}
		if (!jiraTicketData.fields.hasOwnProperty(this.state.businessUnitFieldId)) {
			errorList.push('Please provide a valid value for field \'Business Unit\'');
		}
		if (!jiraTicketData.fields.hasOwnProperty(this.state.applicationFieldId)) {
			errorList.push('Please provide a valid value for field \'Application\'');
		}
		if (!jiraTicketData.fields.hasOwnProperty(this.state.environmentFieldId)) {
			errorList.push('Please provide a valid value for field \'Environment\'');
		}
		return errorList;
	};

	getErrorMessages = () => {
		const errorList = this.state.errorList ? [...this.state.errorList] : [];
		let counter = 0;
		return errorList.map(error => {
			return (
				<div key={counter++}>{error}</div>
			);
		})
	};

	isEmptyString = (string) => {
		return (!string || string.trim().length === 0);
	};


	getFileNames = () => {
		return this.state.attachedFiles.map((file, index) => <div key={index}>{file.path}</div>)
	};


	render() {
		const { classes, isOpen, onClose } = this.props;

		return (
			<div>
				<Dialog   open={isOpen}
						  onClose={onClose}
						  classes={{ paper: classes.dialogPaper }}
				>
					<div className={Style.WebFormHeader}>Create a ticket</div>
					<div className={Style.WebFormContentHolder}>
						<div className={Style.ErrorHolder}>
							{this.getErrorMessages()}
						</div>
						<TextField
							id="outlined-basic"
							label="Summary"
							variant="outlined"
							InputProps={{
								classes: { input: classes.input },
							}}
							InputLabelProps={{
								classes: { root: classes.label },
							}}
							className={classes.textField}
							fullWidth
							value={this.state.summary}
							onChange={this.saveSummary}
						/>
						<TextField
							id="outlined-basic"
							InputProps={{ classes: { input: classes.input }, }}
							InputLabelProps={{ classes: { root: classes.label }, }}
							className={classes.textField}
							fullWidth
							label="Description"
							variant="outlined"
							multiline
							rowsMax={4}
							rows={4}
							value={this.state.description}
							onChange={this.saveDescription}
						/>
						<div>Business Unit</div>
						<Select
							name="Business Unit"
							isMulti
							className={Style.WebFormSelect}
							classNamePrefix="selector"
							options={this.state.businessUnitOptions}
							value={this.state.selectedBusinessUnits}
							onChange={this.saveBusinessUnit}
							maxMenuHeight={200}
						/>
						<div>Application</div>
						<Select
							name="Application"
							className={Style.WebFormSelect}
							classNamePrefix="selector"
							options={this.state.applicationOptions}
							defaultValue={this.props.accessRequestApplication}
							isDisabled
							maxMenuHeight={200}
						/>
						<div>Environment</div>
						<Select
							name="Environment"
							isMulti
							className={Style.WebFormSelect}
							classNamePrefix="selector"
							options={this.state.environmentOptions}
							value={this.state.selectedEnvironments}
							onChange={this.saveEnvironment}
						/>
						<div>Attachment (optional)</div>
						<div className={Style.WebFormDropzone}>
							<Dropzone  onDrop={acceptedFiles => this.setState({attachedFiles: acceptedFiles})}>
								{({getRootProps, getInputProps}) => (
									<section>
									<div {...getRootProps()}>
										<input {...getInputProps()} />
										<p>Drag 'n' drop some files here, or click to select files</p>
										<div>
											{this.getFileNames()}
										</div>
									</div>
									</section>
								)}
							</Dropzone>
						</div>
					</div>
					<div className={Style.ModalButtonHolder}>
						<div onClick={this.createJiraTicket} className={Style.ModalButton}>Create</div>
						<div onClick={this.props.closed} className={Style.ModalButton2}>Cancel</div>
					</div>
				</Dialog>
			</div>
		);
	}
}

export default withStyles(styles)(WebForm);