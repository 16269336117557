import React, { Component } from 'react';
import Style from './LinkItem.module.scss';

class LinkItem extends Component {

    handleClick = () => {
        window.open(this.props.url, '','noopener,noreferrer,');
    };

    render() {
        return (
            <div className={ Style.Link } onClick={ this.handleClick }>
                { this.props.text }
            </div>
        );
    };
}

export default LinkItem;