import React, { Component } from 'react';
import logo from '../../../assets/img/GEOPOST_logo_redblack_rgb.svg';
import Style from './FooterCopyright.module.scss';

class FooterCopyRight extends Component {
    handleOnClick = () => {
        window.open('https://www.dpd.com/group/en/data-privacy-policy/', '','noopener,noreferrer,');
    }

    render() {
        return (
            <div className={ Style.Footer }>
                <div className={ Style.FooterLeft }>
                    <img src={ logo } alt="DPDGroup" className={ Style.Logo }></img>
			        <span className={ Style.Copyright }> © { new Date().getFullYear() } DPD</span>
                </div>
                <div className={ Style.FooterRight }>
                    <span className={ Style.Link } onClick={ this.handleOnClick } >
                        Privacy
                    </span>
                </div>
            </div>
        );
    }
}

export default FooterCopyRight;