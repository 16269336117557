import { Modal } from 'react-bootstrap';
import React, { Component } from 'react';

class AdminModal extends Component {
	render() {
		return (
			<Modal
				show={this.props.show}
				backdrop='static'
				keyboard={false}
				onHide={this.props.onHide}
			>
				<Modal.Header closeButton>
					<Modal.Title>{this.props.modalTitle}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{this.props.modalText}
				</Modal.Body>
				<Modal.Footer>
					{this.props.modalButtons}
				</Modal.Footer>
			</Modal>
		);
	}
}

export default AdminModal;